import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"

let SUBMENU = []

const Media = () => (
  <StaticQuery
    query={graphql`
      query BlogAllMediaQuery {
        allWordpressWpNews(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              excerpt
              categories {
                name
                slug
              }
            }
          }
        }
        gallery: allWordpressWpGallery(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
            }
          }
        }
        videos: allWordpressWpVideos(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "media" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <CssGrid className="internal-page">
          <div>
            <Hero>
              <Content
                fillBackgroundWithImage
                fillBlockWithImage
                backgroundImage="about_ovolo_banner.jpg"
              >
                <h1>Media</h1>
              </Content>
            </Hero>
            {data.submenu.edges.map(({ node }, i) => {
              SUBMENU = node.items
            })}
            <Subnavigation items={SUBMENU} />
            <div className="container">
              <div className="row">
                <div className="col col-xs-12 col-md-9">
                  <div className="blog-grids">
                    <h2>Latest News</h2>
                    {data.allWordpressWpNews.edges.map(({ node }) => (
                      <div key={node.slug} className="grid">
                        <div className="entry-media">
                          {node.featured_media && (
                            <Link to={`/news/${node.slug}`}>
                              <Img
                                src={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes.src
                                }
                                sizes={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes
                                }
                                alt="blog_image_one"
                                className="img-fluid"
                              />
                            </Link>
                          )}
                        </div>
                        <div className="entry-body">
                          <span className="cat">
                            {node.categories &&
                              node.categories.map(
                                category => `${category.name}, `
                              )}
                          </span>
                          <h3>
                            <Link
                              to={`/news/${node.slug}`}
                              dangerouslySetInnerHTML={{ __html: node.title }}
                            />
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{ __html: node.excerpt }}
                          />
                          <div className="read-more-date">
                            <span className="date">{node.date}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="blog-grids">
                    <h2>Latest Galleries</h2>
                    {data.gallery.edges.map(({ node }) => (
                      <div key={node.slug} className="grid">
                        <div className="entry-media">
                          {node.featured_media && (
                            <Link to={`/gallery/${node.slug}`}>
                              <Img
                                src={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes.src
                                }
                                sizes={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes
                                }
                                alt="blog_image_one"
                                className="img-fluid"
                              />
                            </Link>
                          )}
                        </div>
                        <div className="entry-body">
                          <h3>
                            <Link
                              to={`/gallery/${node.slug}`}
                              dangerouslySetInnerHTML={{ __html: node.title }}
                            />
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="blog-grids">
                    <h2>Latest Videos</h2>
                    {data.videos.edges.map(({ node }) => (
                      <div key={node.slug} className="grid">
                        <div className="entry-body">
                          <h3>
                            <Link
                              to={`/videos/${node.slug}`}
                              dangerouslySetInnerHTML={{ __html: node.title }}
                            />
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CssGrid>
      </Layout>
    )}
  />
)

export default Media
